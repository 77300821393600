import './birthday-bot.styles.scss'

const BirthdayBot = () => {
    return (
        <div className='birthday'>
            Birthday bot
        </div>
    )
}

export default BirthdayBot