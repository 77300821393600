import './console.styles.scss'

const ConsoleComponent = () => {
    return (
        <div className='console'>
            Console
        </div>
    )
}

export default ConsoleComponent